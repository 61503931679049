<template>
  <span class="upload-btn icon icon-files" @click.stop="tishi()">
    <input title="文件" type="file" data-type="file" accept="*" @change.stop="sendUploadMessage" 
    :disabled="Upload" 
    />
  </span>
</template>

<script>
import { defineComponent, inject, reactive, toRefs, watchEffect } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "FileUploadButton",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
      Upload:false,
    });
    const store = useStore();
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.activeSession = props.activeSession;
      data.tcccSdk = tcccsdk.value;
    });
    const tishi = ()=>{
      if(data.Upload){
        ElMessage.error("请等待上一文件传送完毕");
      }
    }
    const sendUploadMessage = async (e) => {
      let TID = new Date().getTime();
      if (e.target.files.length > 0) {
        try {
          if (data.tcccSdk && data.activeSession?.sessionId) {
            data.Upload = true
            const createFileMessage = await data.tcccSdk.Chat.createFileMessage(
              {
                sessionId: data.activeSession.sessionId,
                payload: { file: e.target.files[0] },
                onProgress: function (event) {
                  const sendResFileMessage = {
                    ...data.activeSession.lastMessage,
                    progress:event,
                    TID:TID,
                    status: "unSend",
                    payload: {
                      uuid: null,
                      fileName: e.target.files[0].name,
                      fileSize: e.target.files[0].size,
                      fileUrl: null,
                      progress: event,
                    },
                    flow: "out",
                    type: "TIMFileElem",
                    sessionId: data.activeSession.sessionId,
                    nick: data.activeSession.lastMessage.nick || "客服",
                    avatar: data.activeSession.lastMessage.avatar || "https://tccc.qcloud.com/static/media/image.4e354a5e2baf2f5d3f055529561fed9536cb037b.png" ,
                   
                  }
                  store.commit("message/insertMessageIntoMessageList", {
                    message: sendResFileMessage,
                    selectedSession: data.activeSession,
                    TID:TID,
                  })
                  store.commit("session/updateSessionsLastMessage", {
                    type: "receiveMessage",
                    message: sendResFileMessage,
                    TID:TID,
                  });
                }
              }
            );
            const sendRes = await data.tcccSdk.Chat.sendMessage(
              createFileMessage.data
            );
            data.Upload = false
            store.commit("message/insertMessageIntoMessageList", {
              message: sendRes.data.data.message,
              selectedSession: data.activeSession,
              TID:TID,
            });
            store.commit("session/updateSessionsLastMessage", {
              type: "receiveMessage",
              message: sendRes.data.data.message,
              TID:TID,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      e.target.value = "";
    };
    return {
      ...toRefs(data),
      sendUploadMessage,tishi
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;

  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-files {
  margin: 12px 10px 0;
  background: url("../../../../assets/icon/files.png") no-repeat;
  background-size: contain;
}
</style>