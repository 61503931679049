<!-- 客户信息 -->
<!--  右边全部 -->
<template>
   <el-form
    v-if="userId"
    ref="ruleFormRef"
    style="max-width: 600px"
    :model="ruleForm"
    status-icon
    label-width="auto"
    class="demo-ruleForm"
  >
    <el-form-item label="所属企业" >
      <el-input v-model="ruleForm.customer_name" disabled   />
    </el-form-item>
    <el-form-item label="部门">
      <el-input v-model="ruleForm.department" :disabled="disabled"   />
    </el-form-item>
    <el-form-item label="职位">
      <el-input v-model="ruleForm.post" :disabled="disabled"   />
    </el-form-item>
    <el-form-item label="姓名">
      <el-input v-model="ruleForm.name" :disabled="disabled"   />
    </el-form-item>
    <el-form-item label="手机号码" >
      <el-input v-model.number="ruleForm.mobile" disabled />
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input v-model="ruleForm.email" :disabled="disabled"   />
    </el-form-item>
    <el-form-item label="备注名">
      <el-input v-model="ruleForm.notename" :disabled="disabled"   />
    </el-form-item>
    <el-form-item label="备注">
      <el-input    :rows="6"
      type="textarea" v-model="ruleForm.remark" :disabled="disabled"   />
    </el-form-item>
    <el-form-item>
      <el-button v-if="!disabled" type="primary" @click="submitForm()">
        提交更新
      </el-button>
      <el-button v-if="disabled" @click="resetForm()">修改</el-button>
    </el-form-item>
  </el-form>
</template>
 
<script  setup >
import { computed,watch,reactive,ref } from "vue";
import { useStore} from "vuex";
import { getUserInfo,editUser  } from "../../../utils/api"
import { ElMessage } from 'element-plus'

const store = useStore();
const agentInfo = computed(() => store.state.session.selectedSession);  // 
const userId = ref()



const ruleFormRef = ref()
const disabled = ref(true)

// 表单参数
const ruleForm = reactive({
  uid:"",
  headimg:"", // 
  customer_name: '',
  department: '',
  post: '',
  mobile: '',
  email:"",
  name: '',
  notename: '',
  remark: '',
})

watch(agentInfo, (newData) => {
 if(newData){
    try{
      userId.value = newData.userId
      getUser()
    }catch(e){ console.log(e) }
 }
}, {immediate: true, deep: true})

// 获取当前客户信息
const getUser = async()=>{
  disabled.value = true
  const res = await getUserInfo({uid:userId.value})
  try{
    ruleForm.headimg = res.data.headimg
    ruleForm.customer_name = res.data.customer_name
    ruleForm.department = res.data.department
    ruleForm.post = res.data.post
    ruleForm.mobile = res.data.mobile
    ruleForm.email = res.data.email
    ruleForm.name = res.data.name
    ruleForm.notename = res.data.notename
    ruleForm.remark = res.data.remark
    ruleForm.uid = userId.value
  }catch(error){
    console.log(error)
  }
}

// 提交
const submitForm = async() => {
  disabled.value = !disabled.value
  const res = await editUser(ruleForm)
  console.log("res123123",res)
  if(res.code == 0){
    getUser()
    ElMessage({
				message: "修改成功" ,
				type: 'success',
			  })
  }
      
}
// 取消
const resetForm = () => {
  disabled.value = !disabled.value
}

</script>
 
<style lang="scss" scoped>
 
</style>
 