<template>
  <div class="message-image">
    <div style="position: relative"   >
      <el-image  :src="data.url"  :onLoad="onLoadedMetadata"  :preview-src-list="srcList" > 
        <template #viewer>
        <div class="viewerDownload" title="点击下载">
            <el-icon @click="getBowserUrl(srcList[0])" ><Download /></el-icon>
        </div>
    </template>
      </el-image>     
      <div class="fileSizeStatus">
        <div style="color: #999999; font-size: 12px">{{ data.size }}</div>
      </div>
    </div>
    <div class="progress" v-if="data.progress">
      <progress :value="data.progress" max="1"></progress>
    </div>
  </div>
  <div
    class="dialog"
    v-if="show"
    @click="toggleClose"
    @click.stop.right="clickImgRight"
  >
    <div class="dialog-box" @click="toggleClose">
      <img
        :class="[isWidth ? 'isWidth' : 'isHeight']"
        :src="data.message.payload.imageInfoArray[0].url"
        @click="toggleClose"
      />

    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, toRefs, computed } from "vue";
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
    isH5ShowTitle: {
      type: Boolean,
      default: false,
    },
  },
  emits:['imgMetadata'],
  setup(props,{emit}) {
    const data = reactive({
      data: {},
      show: false,
      isH5ShowTitle: false,
      imgDomID: "",
      srcList:[],
    });
    watchEffect(() => {
      data.data = props.data;
      data.imgDomID = data.data.message.ID + "image";
      data.srcList.push(data.data.message.payload.imageInfoArray[0].url) 
    });
    watchEffect(() => {
      data.isH5ShowTitle = props.isH5ShowTitle;
    });

    const isWidth = computed(() => {
      const { width = 0, height = 0 } =
        data.data.message.payload.imageInfoArray[0];
      return width >= height;
    });

    const clickImgRight = (e) => {
      e.preventDefault();
    };

    const toggleShow = () => {
      if (!data.data.progress) {
        data.show = true;
      }
    };
    const toggleClose = () => {
      if (!data.data.progress) {
        data.show = false;
      }
    };
    const getBowserUrl = (e) => {
      console.log(e)
      window.location.href = e 
    };
    const onLoadedMetadata = (e) => {
      console.log( 'onLoadedMetadata', e)
      emit("imgMetadata",e)
    };


    return {
      ...toRefs(data),
      clickImgRight,
      toggleShow,
      toggleClose,getBowserUrl,onLoadedMetadata,
      isWidth,
    };
  },
});
</script>
<style lang="scss" scoped>
.message-image {
  overflow: hidden;
  position: relative;
  max-width: min(calc(100vw - 180px), 300px);
  max-height: min(calc(100vw - 180px), 300px);
  .message-img {
    max-width: min(calc(100vw - 180px), 300px);
    max-height: min(calc(100vw - 180px), 300px);
  }

  .progress {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    left: 0;
    top: 0;
    background: rgba(#000000, 0.5);
    display: flex;
    align-items: center;

    progress {
      color: #006eff;
      appearance: none;
      border-radius: 0.25rem;
      background: rgba(#ffffff, 1);
      width: 100%;
      height: 0.5rem;

      &::-webkit-progress-value {
        background-color: #006eff;
        border-radius: 0.25rem;
      }

      &::-webkit-progress-bar {
        border-radius: 0.25rem;
        background: rgba(#ffffff, 1);
      }

      &::-moz-progress-bar {
        color: #006eff;
        background: #006eff;
        border-radius: 0.25rem;
      }
    }
  }
}

.dialog {
  position: fixed;
  z-index: 12;
  width: 100vw;
  height: calc(100vh);
  background: rgba(#000000, 0.3);
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    display: flex;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.49);
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px;
  }

  &-box {
    display: flex;
    flex: 1;
    max-height: 90%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
}

.isWidth {
  max-height: 100%;
  max-width: 100%;
}

.isHeight {
  max-height: 100%;
  max-width: 100%;
}

.fileSizeStatus {
  position: absolute;
  right: 5px;
  bottom: 0;
}

</style>
