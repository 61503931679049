<template>
  <div class="messageSenderContainer">
    <ChatInputActions
      :activeSession="activeSession"
      @clickEmoji="clickEmoji"
      @clickTransfer="clickTransfer"
    >
    </ChatInputActions>
    <MessageInputEditor
      ref="editor"
      @sendMessage="sendMessage"
    ></MessageInputEditor>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRefs,
  watchEffect,
  watch
} from "vue";
import MessageInputEditor from "./message-input-editor.vue";
import ChatInputActions from "./message-input-actions.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ChatMessageSender",
  props: {
    activeSession: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    MessageInputEditor,
    ChatInputActions,
  },
  setup(props, ctx) {
    const data = reactive({
      tcccSdk: null,
      activeSession: {},
      quoteMessage: computed(() => store?.state?.message?.quoteMessage),
      sending : computed(() => store?.state?.message?.sending)
    });
    const store = useStore();
    const tcccsdk = inject("tcccsdk");
    watchEffect(() => {
      data.activeSession = props.activeSession;
      data.tcccSdk = tcccsdk.value;
    });
    watch(()=>data.sending,( newValue)=>{
      editor.value?.setEditorContent(newValue);
      store.commit("message/updateSending","");
    })

    const editor = ref();
    const clickEmoji = (options) => {
      editor?.value?.addEmoji(options);
    };
    const clickTransfer = () => {
      ctx.emit("clickTransfer");
    };
    const sendMessage = async () => {
      const editorContentList = editor.value?.getEditorContent();
      if (!editorContentList || !data.activeSession) return;
      await sendMessages(editorContentList);
      editor.value?.resetEditor();
    };
    const sendMessages = async (messageList) => {
      messageList?.forEach(async (content) => {
        try {
          if (data.tcccSdk && data.activeSession?.sessionId) {
            switch (content?.type) {
              case "text":
                {
                  const textMessageContent = JSON.parse(
                    JSON.stringify(content?.payload?.text)
                  );
                  // 禁止发送空消息
                  if (!textMessageContent) {
                    break;
                  }
                  const createTextMessage =
                    await data.tcccSdk.Chat.createTextMessage({
                      sessionId: data.activeSession.sessionId,
                      payload: {
                        text: textMessageContent,
                      },
                      cloudCustomData: data.quoteMessage
                        ? JSON.stringify([data.quoteMessage])
                        : "",
                    });
                  await data.tcccSdk.Chat.sendMessage(createTextMessage.data);
                  store.commit("message/insertMessageIntoMessageList", {
                    message: createTextMessage.data,
                    selectedSession: data.activeSession,
                  });
                  store.commit("session/updateSessionsLastMessage", {
                    type: "receiveMessage",
                    message: createTextMessage.data,
                  });
                }
                break;
              case "image":
                {
                  const createImageMessage =
                    await data.tcccSdk.Chat.createImageMessage({
                      sessionId: data.activeSession.sessionId,
                      payload: { file: content?.payload?.file },
                      cloudCustomData: data.quoteMessage
                        ? JSON.stringify([data.quoteMessage])
                        : "",
                    });
                  await data.tcccSdk.Chat.sendMessage(createImageMessage.data);
                  store.commit("message/insertMessageIntoMessageList", {
                    message: createImageMessage.data,
                    selectedSession: data.activeSession,
                  });
                  store.commit("session/updateSessionsLastMessage", {
                    type: "receiveMessage",
                    message: createImageMessage.data,
                  });
                }
                break;
              case "video":
                {
                  const createVideoMessage =
                    await data.tcccSdk.Chat.createVideoMessage({
                      sessionId: data.activeSession.sessionId,
                      payload: { file: content?.payload?.file },
                      cloudCustomData: data.quoteMessage
                        ? JSON.stringify([data.quoteMessage])
                        : "",
                    });
                  await data.tcccSdk.Chat.sendMessage(createVideoMessage.data);
                  store.commit("message/insertMessageIntoMessageList", {
                    message: createVideoMessage.data,
                    selectedSession: data.activeSession,
                  });
                  store.commit("session/updateSessionsLastMessage", {
                    type: "receiveMessage",
                    message: createVideoMessage.data,
                  });
                }
                break;
              case "file":
                {
                  const createFileMessage =
                    await data.tcccSdk.Chat.createFileMessage({
                      sessionId: data.activeSession.sessionId,
                      payload: { file: content?.payload?.file },
                      cloudCustomData: data.quoteMessage
                        ? JSON.stringify([data.quoteMessage])
                        : "",
                    });
                  await data.tcccSdk.Chat.sendMessage(createFileMessage.data);
                  store.commit("message/insertMessageIntoMessageList", {
                    message: createFileMessage.data,
                    selectedSession: data.activeSession,
                  });
                  store.commit("session/updateSessionsLastMessage", {
                    type: "receiveMessage",
                    message: createFileMessage.data,
                  });
                }
                break;
              default:
                break;
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      store.commit("message/updateQuoteMessage", undefined);
    };
    onMounted(() => {
      editor.value?.resetEditor();
      store.commit("message/updateQuoteMessage", undefined);
    });

    return {
      ...toRefs(data),
      editor,
      clickEmoji,
      sendMessage,
      clickTransfer,
    };
  },
});
</script>

<style lang="scss" scoped>
.messageSenderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  height: fit-content;
  max-height: 200px;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-width: 1px 0 0 0;
}
</style>
