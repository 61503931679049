<template>
  <div class="message-audio" :class="[data.message.flow === 'out' && 'reserve']" @click.stop="play"
    :style="`width: ${data?.second * 10 + 40}px`">
    <i class="icon icon-voice" v-if="secondNum <= 0" :class="[data.message.flow === 'out' && 'icon-reserve']"></i>
    <i class="icon icon-voice-gif" v-else-if="secondNum > 0"
      :class="[data.message.flow === 'out' && 'icon-reserve']"></i>
    <label>{{ data.second }}s</label>
    <audio :id="data.url" ref="audio" :src="data.url"></audio>
  </div>
</template>

<script>
import { defineComponent, watch, reactive, toRefs, ref ,computed} from "vue";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      data: {},
      show: false,
      secondNum: 0,
    });
    const store = useStore();
    const audio = ref(null);
    const audioUrl = computed(() => store.state.session.audioUrl);
    watch(
      () => props.data,
      () => {
        data.data = props.data;
      },
      { deep: true, immediate: true }
    );
    watch(
      () => audioUrl,
      (newVa) => {
        if (data.data?.url != newVa?.value) {
          data.secondNum = 0
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      () => data.secondNum,
      (newVa) => {
        if (newVa && data.secondNum > 0) {
          setTimeout(() => {
            data.secondNum--
          }, 1000)
        }
      },
      { deep: true, immediate: true }
    );

    const play = () => {
      pauseaudios()
      try {
        // const audio = new Audio(data.data?.url);
        const audio = document.getElementById(data.data?.url)
        store.commit("session/getAudioUrl", data.data?.url);
        console.dir(audio)
        audio.load();
        if (!audio.paused) {
          audio.pause();
          audio.load();
          data.secondNum = 0
        }
        if (audio.paused && data.secondNum < 1) {
          audio.play();
          data.secondNum = data.data.second
          data.show = true;
        } else {
          audio.pause();
          audio.load();
          data.secondNum = 0
          data.show = false;
        }
      } catch (e) {
        console.log(e);
      }
    };
    const pauseaudios = () => {
      var audios = document.getElementsByTagName("audio");
      // 给play事件绑定暂停函数
      Array.prototype.forEach.call(audios, function (i) {
        i.addEventListener("play", pauseAll.bind(i));
      })
      // 暂停函数
      function pauseAll() {
        var self = this;
        [].forEach.call(audios, function (i) {
          // 将audios中其他的audio全部暂停
          i !== self && i.pause();
          i !== self && i.load();
        })
      }
    }


    return {
      ...toRefs(data),
      audio,
      play,
    };
  },
});
</script>
<style lang="scss" scoped>
.message-audio {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;

  .icon {
    margin: 0 4px;
  }

  .icon-voice {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icon/voice.png") no-repeat;
    background-size: contain;
  }

  .icon-voice-gif {
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icon/voice.gif") no-repeat;
    background-size: contain;
  }

  audio {
    width: 0;
    height: 0;
  }
}

.reserve {
  flex-direction: row-reverse;
}
</style>
