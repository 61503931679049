<!--  右边全部 -->
<template>
  <div class="rightIndex">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="客户信息" name="info">
        <information></information>
      </el-tab-pane>
      <el-tab-pane label="快捷回复" name="fast">
        <fastreply></fastreply>
      </el-tab-pane>
      <el-tab-pane label="客服情况" name="kefu">
        <kefu :activeName="activeName"></kefu>
      </el-tab-pane>
      <el-tab-pane v-if="false" label="发送邮件" name="youjian">
        <EmailIndex :activeName="activeName"></EmailIndex>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import information from "./information/index.vue"
import fastreply from "./fastreply/index.vue"
import kefu from "./kefu/index.vue"
import EmailIndex from "./email/index.vue"

const activeName = ref('info')
const handleClick = (tab, event) => {
  console.log(tab, event)
}
</script>

<style lang="scss" scoped>
.rightIndex {
  width: 600px;
  max-height: calc(100vh - 60px) ;
  overflow: auto;
}

.demo-tabs {
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
}

.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>