const state = {
  // 当前登录座席配置的信息
  agentInfo: {
    connectionState: "disconnected",
    status: "offline",
    logicStatus: "ready",
    reason: "",
    IM: {
      total: 0,
      used: 0,
    },
    assignCall: true,
    assignIM: true,
    existCall: true,
    existIM: true,
    queueCount: {},
  },
};

const mutations = {
  // 更新座席的信息
  updateAgentInfo(state, agentInfo) {
    state.agentInfo = {
      ...state.agentInfo,
      ...agentInfo,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
